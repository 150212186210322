.blog-index {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-index-header {
  text-align: center;
  margin-bottom: 2rem;
}

.blog-index-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.blog-index-header p {
  font-size: 1.2rem;
  color: #666;
}

.blog-filters {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 0.8rem 1rem;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.search-input:focus {
  outline: none;
  border-color: #0066cc;
}

.tag-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.tag-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background: #f0f0f0;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.tag-button:hover {
  background: #e0e0e0;
}

.tag-button.active {
  background: #0066cc;
  color: white;
}

.blog-posts-container {
  position: relative;
  width: 100%;
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

/* Responsive design */
@media (max-width: 768px) {
  .blog-index {
    padding: 1rem;
  }

  .blog-index-header h1 {
    font-size: 2rem;
  }

  .blog-posts-container {
    height: 400px !important;
  }
}
