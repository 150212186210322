@import './variables.css';

/* Footnotes Container */
.blog-post-layout .footnotes-container {
  position: absolute;
  top: 0;
  right: calc(-1 * (var(--right-column-width) + var(--spacing-md)));
  width: var(--right-column-width);
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

/* Sidenote */
.blog-post-layout .sidenote {
  position: absolute;
  width: calc(100% + 4rem);
  font-size: var(--font-size-sm);
  line-height: 1.3;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: #f8f9fa;
  border-left: 2px solid #dee2e6;
  border-radius: var(--border-radius);
  color: #495057;
  transition: var(--transition-base);
  max-width: 350px;
  box-sizing: border-box;
  pointer-events: auto;
  opacity: 0.85;
  transform: translateX(0);
  transition: all 0.2s ease;
  cursor: pointer;
  /* Adjust vertical alignment to match the footnote index */
  transform-origin: left top;
  margin-top: calc(var(--spacing-xs) * -1); /* Fine-tune vertical alignment */
}

/* Sidenote number alignment */
.blog-post-layout .sidenote sup {
  position: relative;
  top: 0;
  margin-right: var(--spacing-xs);
}

.blog-post-layout .sidenote:hover,
.blog-post-layout .sidenote.active {
  opacity: 1;
  transform: translateX(-0.5rem);
  background-color: #e9ecef;
  border-left-color: #339af0;
}

.footnote {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 0.85em;
  vertical-align: super;
  line-height: 0;
  position: relative;
  top: -0.15em;
}

.mobile-footnote-content {
  display: none;
}

/* Hide footnotes container on smaller screens */
@media (max-width: 1200px) {
  .blog-post-layout .footnotes-container {
    display: none;
  }

  .mobile-footnote-content.active {
    display: block;
    margin: var(--spacing-xs) 0;
    padding: var(--spacing-sm);
    background: var(--bg-hover);
    border-radius: var(--border-radius);
    font-size: var(--font-size-sm);
    line-height: 1.4;
  }
}
