@import './variables.css';

/* Layout Container */
.blog-post-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-md);
  width: 100%;
  min-height: 100vh;
}

/* Grid Layout */
.blog-post-layout {
  display: grid;
  grid-template-columns: minmax(220px, 18%) minmax(auto, 55%) minmax(280px, 27%);
  gap: var(--spacing-xl);
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
}

/* Main Content */
.main-content {
  grid-column: 2;
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 0 var(--spacing-lg);
  box-sizing: border-box;
  position: relative;
}

/* Article Header */
.article-header {
  margin-bottom: var(--spacing-xl);
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--border-color);
}

.article-header h1 {
  margin-bottom: var(--spacing-md);
  font-size: 3rem;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

.article-meta {
  font-family: 'Inter', sans-serif;
  font-size: 0.95rem;
  color: var(--text-muted);
  display: flex;
  gap: var(--spacing-sm);
  flex-wrap: wrap;
}

/* Table of Contents */
.table-of-contents {
  position: sticky;
  top: var(--spacing-xl);
  max-height: calc(100vh - var(--spacing-xl) * 2);
  overflow-y: auto;
  padding-right: var(--spacing-md);
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}

.table-of-contents::-webkit-scrollbar {
  width: 6px;
}

.table-of-contents::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

/* Sidenotes */
.sidenotes-container {
  grid-column: 3;
  position: relative;
}

.sidenote {
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--text-muted);
  margin-bottom: var(--spacing-md);
  padding-left: var(--spacing-md);
  border-left: 2px solid var(--border-color);
  transition: border-color 0.2s ease;
}

.sidenote.active {
  border-left-color: var(--primary-color);
  color: var(--text-color);
}

/* Content Sections */
.content section {
  margin-bottom: var(--spacing-xl);
}

/* Images */
.content figure {
  margin: var(--spacing-xl) -10%;
  width: 120%;
}

.content figure img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.content figure figcaption {
  margin-top: var(--spacing-sm);
  font-size: 0.9rem;
  color: var(--text-muted);
  text-align: center;
}

/* Responsive Layout */
@media (max-width: 1200px) {
  .blog-post-layout {
    grid-template-columns: minmax(200px, 20%) 1fr;
  }
  
  .sidenotes-container {
    display: none;
  }
  
  .content figure {
    margin: var(--spacing-lg) 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blog-post-layout {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
  
  .table-of-contents {
    display: none;
  }
  
  .main-content {
    padding: 0;
  }
  
  .article-header h1 {
    font-size: 2.25rem;
  }
}
