/* Import shared systems */
@import './Theme.css';
@import './Layout.css';

/* Sidenotes System Variables */
:root {
  --sidenote-width: calc(var(--right-sidebar-width) - var(--grid-gap));
  --sidenote-offset: var(--grid-gap);
}

/* Sidenotes Container */
.footnotes-container {
  position: absolute;
  top: 0;
  right: calc(-1 * (var(--sidenote-width) + var(--sidenote-offset)));
  width: var(--sidenote-width);
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

/* Individual Sidenote */
.sidenote {
  position: absolute;
  font-size: var(--font-size-small);
  color: var(--text-secondary);
  padding: var(--spacing-md);
  background-color: var(--background-secondary);
  border-left: 3px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);
  pointer-events: auto;
  max-width: var(--sidenote-width);
  opacity: 0.85;
  transform: translateX(0);
  transition: all var(--transition-base) var(--transition-timing);
  cursor: pointer;
  margin-top: calc(-1.5 * var(--spacing-lg));
}

.sidenote:hover,
.sidenote.active {
  opacity: 1;
  transform: translateX(calc(-1 * var(--spacing-md)));
  background-color: var(--background-accent);
  border-left-color: var(--accent-color);
  box-shadow: var(--shadow-md);
}

.sidenote.active {
  background-color: var(--background-accent-strong);
  box-shadow: var(--shadow-lg);
}

/* Footnote Reference */
.footnote {
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: var(--spacing-xs) var(--spacing-sm);
  margin: calc(-1 * var(--spacing-xs)) calc(-1 * var(--spacing-sm));
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-base) var(--transition-timing);
  background-color: transparent;
}

.footnote:hover {
  background-color: var(--background-accent);
  color: var(--link-hover-color);
}

.footnote.active {
  background-color: var(--background-accent-strong);
  color: var(--link-hover-color);
  box-shadow: var(--shadow-sm);
}

.footnote sup {
  color: inherit;
  transition: transform var(--transition-base) var(--transition-timing);
  font-size: 0.75em;
  font-weight: 500;
}

.footnote:hover sup {
  transform: translateY(-1px);
}

/* Responsive Adjustments */
@media (max-width: var(--breakpoint-lg)) {
  :root {
    --sidenote-width: calc(var(--right-sidebar-width) * 0.8);
  }
}

@media (max-width: var(--breakpoint-md)) {
  .footnotes-container {
    display: none;
  }
}
