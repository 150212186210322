.vizcom-container {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 10px;
    margin: 20px auto;
    width: 95%;
    font-family: Arial, sans-serif;
}

.vizcom-container h2, .vizcom-container h3 {
    color: #333;
}

.vizcom-container p {
    color: #666;
    font-size: 16px;
}

.details-button {
    display: inline-block;
    background-color: #FF56b3;
    color: #fff;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.details-button:hover {
    background-color: #004494;
    box-shadow: none;
}

.details {
    margin-top: 10px;
    padding-top: 20px;
    animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
