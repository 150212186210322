@import './variables.css';

/* Blog Post Header */
.blog-post header {
  margin-bottom: var(--spacing-lg);
}

.blog-post header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  color: var(--heading-color);
}

.blog-post-metadata {
  font-size: var(--font-size-sm);
  color: var(--text-color-light);
  margin-bottom: var(--spacing-md);
}

/* Tags */
.blog-post .tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: var(--spacing-sm) 0;
}

.blog-post-tag {
  font-size: 0.875rem;
  color: var(--text-color-light);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: var(--bg-hover);
  text-decoration: none;
  transition: background 0.2s ease-in-out;
}

.blog-post-tag:hover {
  background: var(--bg-hover-dark);
}

.blog-post-tag:active {
  background: var(--bg-hover-darker);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .blog-post header h1 {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .blog-post header h1 {
    font-size: 1.75rem;
  }
}
