@import './variables.css';

/* Code Blocks */
.blog-post .content {
  pre {
    margin: var(--spacing-md) 0;
    padding: var(--spacing-sm);
    background: var(--code-bg);
    border-radius: 0.5rem;
    overflow-x: auto;
    
    code {
      padding: 0;
      background: none;
      border-radius: 0;
    }
  }
  
  code {
    font: 0.9em var(--font-mono);
    padding: 0.2em 0.4em;
    background: var(--code-bg);
    border-radius: 0.25rem;
  }
}

.blog-post pre {
  max-width: 100%;
  overflow-x: auto;
}

/* Code blocks */
.blog-post pre code {
  display: block;
  padding: 1rem;
  overflow-x: auto;
  max-width: 100%;
  white-space: pre-wrap;
}

/* Code Block Wrapper */
.code-block-wrapper {
  position: relative;
  margin: 2rem 0;
}

.code-block {
  position: relative;
  background: var(--bg-color-dark);
  border-radius: 12px;
  border: 1px solid rgba(82, 82, 89, 0.32);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 
              0 8px 16px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding: 0.25rem;  
}

.code-block:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 
              0 12px 24px rgba(0, 0, 0, 0.16);
}

/* Code Block Base Styling */
pre[class*="language-"] {
  background: transparent;
  border-radius: 8px;
  padding: 0.75rem;  
  margin: 0;
  overflow-x: auto;
  background: rgba(0, 0, 0, 0.2);  
}

code[class*="language-"] {
  font-family: 'Fira Code', 'Space Mono', monospace;
  font-size: 0.9em;
  line-height: 1.6;
  color: var(--text-color);
  tab-size: 2;
  hyphens: none;
  text-shadow: none !important;
}

/* Code Block Title */
.code-title {
  font-size: 0.65em;
  color: #8b949e;
  background: var(--bg-color-dark);
  padding: 0.3rem 0.8rem;
  font-family: 'Space Mono', monospace;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  border: 1px solid rgba(82, 82, 89, 0.32);
  border-radius: 4px;
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
  z-index: 1;
  opacity: 0.7;
  transition: all 0.2s ease;
  backdrop-filter: blur(8px);
  
  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }
}

/* Copy Button */
.copy-button {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  padding: 0.5rem;
  background: var(--bg-color-dark);
  border: 1px solid rgba(82, 82, 89, 0.32);
  border-radius: 6px;
  color: #8b949e;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
}

.code-block:hover .copy-button {
  opacity: 0.7;
}

.copy-button:hover {
  opacity: 1 !important;
  color: var(--text-color);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.copy-button.copied {
  color: #7ee787;
  border-color: rgba(126, 231, 135, 0.4);
  background: rgba(126, 231, 135, 0.1);
}

/* Syntax Highlighting - Base Colors */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #8b949e;
  font-style: italic;
}

.token.namespace {
  opacity: 0.7;
}

.token.string,
.token.attr-value {
  color: #a5d6ff;
}

.token.punctuation,
.token.operator {
  color: #79c0ff;
}

.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #ffa657;
}

.token.selector,
.token.attr-name,
.token.char,
.token.builtin,
.token.inserted {
  color: #7ee787;
}

.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #79c0ff;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #ff7b72;
}

.token.function {
  color: #d2a8ff;
}

.token.regex,
.token.important {
  color: #a5d6ff;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

/* Language-specific highlighting */
[class*="language-"] .token {
  background: transparent !important;
}

/* JavaScript/TypeScript */
.language-javascript .token.template-string,
.language-typescript .token.template-string {
  color: #a5d6ff !important;
}

.language-javascript .token.dom,
.language-typescript .token.dom,
.language-javascript .token.console,
.language-typescript .token.console {
  color: #79c0ff !important;
}

/* Python */
.language-python .token.decorator {
  color: #ff7b72 !important;
}

.language-python .token.string-interpolation {
  color: #a5d6ff !important;
}

.language-python .token.self {
  color: #ff7b72 !important;
}

/* CSS/SCSS */
.language-css .token.property,
.language-scss .token.property {
  color: #79c0ff !important;
}

.language-css .token.function,
.language-scss .token.function {
  color: #d2a8ff !important;
}

/* HTML/XML */
.language-html .token.tag,
.language-xml .token.tag {
  color: #7ee787 !important;
}

.language-html .token.attr-name,
.language-xml .token.attr-name {
  color: #79c0ff !important;
}

/* GLSL */
.language-glsl .token.keyword {
  color: #ff7b72 !important;
}

.language-glsl .token.builtin {
  color: #79c0ff !important;
}

/* C/C++ */
.language-cpp .token.preprocessor {
  color: #ff7b72 !important;
}

.language-cpp .token.macro {
  color: #d2a8ff !important;
}

/* Markdown */
.language-markdown .token.title,
.language-markdown .token.title .token.punctuation {
  color: #ff7b72 !important;
  font-weight: bold;
}

.language-markdown .token.blockquote.punctuation {
  color: #8b949e;
}

.language-markdown .token.code {
  color: #7ee787;
}

.language-markdown .token.url .token.content {
  color: #a5d6ff;
}

/* JSON */
.language-json .token.property {
  color: #79c0ff !important;
}

/* YAML */
.language-yaml .token.key {
  color: #79c0ff !important;
}

/* Shell */
.language-shell .token.function {
  color: #d2a8ff !important;
}

/* Diff */
.token.deleted {
  color: #ffa198;
  background: rgba(255, 129, 130, 0.2);
}

.token.inserted {
  color: #7ee787;
  background: rgba(126, 231, 135, 0.2);
}

/* Line Numbers */
.line-number {
  user-select: none;
  margin-right: 1rem;
  color: #8b949e;
  border-right: 1px solid var(--border-color);
  padding-right: 1rem;
}

/* Scrollbars */
pre::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

pre::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0 0 12px 12px;
}

pre::-webkit-scrollbar-thumb {
  background: rgba(75, 85, 99, 0.3);
  border: 4px solid var(--bg-color-dark);
  border-radius: 12px;
  transition: background 0.2s ease;
}

pre::-webkit-scrollbar-thumb:hover {
  background: rgba(75, 85, 99, 0.5);
}

/* Selection */
pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: rgba(38, 79, 120, 0.6);
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: rgba(38, 79, 120, 0.6);
}

/* Header Styling Fix */
h3 {
  writing-mode: horizontal-tb !important;
  text-orientation: mixed !important;
  font-size: 1.5rem !important;
  margin: 2rem 0 1rem !important;
  color: var(--text-color) !important;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 0.5rem;
}

/* Inline Code */
:not(pre) > code {
  background: var(--bg-color-dark);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid var(--border-color);
  vertical-align: middle;
  color: var(--text-color);
}
