/* Portfolio.css */
@import './shared/Sidenotes.css';

:root {
  --default-padding: 2rem;
  --mobile-padding: 1rem;
  --paragraph-font-size: 16px;
  --h2-font-size: 24px;
  --link-color: #0066cc;
  --link-hover-color: #004499;
  --text-color: #333;
}

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Container */
.portfolio-container {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  pointer-events: auto !important;
}

/* Layout */
.portfolio-layout {
  display: grid;
  grid-template-columns: 250px minmax(600px, 800px) 300px;
  gap: 2rem;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  pointer-events: auto !important;
}

/* Main Content */
.main-content {
  grid-column: 2;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  padding: 0;
  margin: 0 auto;
}

/* Portfolio-specific sidenote overrides */
.portfolio-layout .footnotes-container {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  grid-column: 3;
  width: 300px !important;
  height: 0;
  overflow: visible;
}

.portfolio-layout .sidenote {
  position: absolute;
  left: 2rem;
  width: calc(100% - 2rem);
  max-width: none;
  margin-top: calc(-1.5 * var(--spacing-lg));
}

.portfolio-layout .sidenote:hover,
.portfolio-layout .sidenote.active {
  transform: translateX(-0.5rem);
}

/* Left Sidebar */
.left-sidebar {
  grid-column: 1;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 2rem 1rem;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
  pointer-events: auto !important;
}

.left-sidebar nav {
  position: sticky;
  top: 2rem;
}

.left-sidebar h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.left-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-sidebar li {
  padding: 0.5rem 0;
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.left-sidebar li:hover {
  color: #0066cc;
}

/* Navigation Styles */
.portfolio-navigation {
  position: relative;
  z-index: 1001;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  pointer-events: auto !important;
}

.portfolio-navigation h3 {
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
}

.portfolio-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  pointer-events: auto !important;
}

.portfolio-navigation li {
  margin-bottom: 0.75rem;
  position: relative;
  z-index: 1002;
  pointer-events: auto !important;
}

.portfolio-navigation a {
  display: block;
  padding: 0.75rem 1rem;
  color: #333;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer !important;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  font-size: 0.95rem;
  font-weight: 500;
  position: relative;
  z-index: 1003;
  pointer-events: auto !important;
}

.portfolio-navigation a:hover,
.portfolio-navigation a:focus {
  background-color: #007bff;
  color: #fff;
  border-color: #0056b3;
  transform: translateX(5px);
  text-decoration: none;
  outline: none;
}

.portfolio-navigation .nav-button {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  color: #333;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer !important;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: left;
  position: relative;
  z-index: 1003;
  pointer-events: auto !important;
}

.portfolio-navigation .nav-button:hover,
.portfolio-navigation .nav-button:focus {
  background-color: #007bff;
  color: #fff;
  border-color: #0056b3;
  transform: translateX(5px);
  outline: none;
}

/* Profile Header */
.profile-header {
  text-align: center;
  margin-bottom: 3rem;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.contact {
  margin-top: 1rem;
}

.contact a {
  font-size: var(--paragraph-font-size);
  margin: 0 1rem;
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact a:hover {
  color: var(--link-hover-color);
}

/* Section Styles */
.portfolio-section {
  scroll-margin-top: 80px;
  padding-top: 2rem;
  margin-bottom: 4rem;
  position: relative;
}

.portfolio-section h2 {
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 600;
}

.portfolio-section h3 {
  margin-bottom: 0.5rem;
  color: #444;
  font-weight: 500;
}

.portfolio-section p {
  font-size: var(--paragraph-font-size);
  color: var(--text-color);
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 1rem;
}

.portfolio-section ul {
  list-style-type: none;
  padding-left: 0;
}

.portfolio-section li {
  margin-bottom: 0.75rem;
  line-height: 1.6;
}

/* Right Sidebar for Footnotes */
.footnotes-container {
  grid-column: 3;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100%;
  pointer-events: none;
}

.sidenote {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: 250px;
  margin-left: -1rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .portfolio-layout {
    grid-template-columns: 200px minmax(500px, 700px) 250px;
    gap: 1rem;
  }
}

@media (max-width: 900px) {
  .portfolio-layout {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .main-content {
    padding: 0;
  }
  
  .footnotes-container {
    display: none;
  }
}

@media (max-width: 991px) {
  .profile-image {
    width: 100px;
    height: 100px;
  }

  .contact a {
    display: block;
    margin: 0.5rem 0;
  }

  .portfolio-section {
    padding: var(--mobile-padding);
  }

  .portfolio-section h2 {
    font-size: calc(var(--h2-font-size) * 0.9);
  }

  .portfolio-section p {
    font-size: calc(var(--paragraph-font-size) * 0.9);
  }
}

@media (max-width: 768px) {
  .portfolio-layout {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

/* Add scroll margin for smooth scrolling */
section[id] {
  scroll-margin-top: 2rem;
}