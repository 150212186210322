.reu-container {
    background: #f9f9f9; /* Matching the background color */
    border: 1px solid #ddd; /* Matching the border style */
    border-radius: 20px; /* Matching the border radius */
    padding: 10px; /* Matching the padding */
    margin: 20px auto; /* Centering in the available space and matching the margin */
    width: 95%; /* Matching the width */
    font-family: Arial, sans-serif;
}

.reu-container h2, .reu-container h3 {
    color: #333; /* Matching the color of headings */
}

.reu-container p {
    color: #666; /* Matching the color of paragraphs */
    font-size: 16px;
}

.details-button {
    display: inline-block;
    background-color: #FF56b3; /* Matching the background color */
    color: #fff;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adding box shadow */
}

.details-button:hover {
    background-color: #004494; /* Matching the hover background color */
    box-shadow: none; /* Removing box shadow on hover */
}

.details {
    margin-top: 10px;
    padding-top: 20px; /* Matching the padding */
    animation: slideIn 0.5s ease-out; /* Matching the animation */
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px); /* Matching the initial transform */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Matching the final transform */
    }
}
