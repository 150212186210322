/* Shared Layout Structure */
:root {
  /* Layout variables */
  --sidebar-width: 250px;
  --content-min-width: 600px;
  --content-max-width: 800px;
  --right-sidebar-width: 300px;
  --container-max-width: 1350px;
  --grid-gap: 2rem;
  --container-padding: 2rem;
  
  /* Responsive breakpoints */
  --breakpoint-lg: 1200px;
  --breakpoint-md: 992px;
  --breakpoint-sm: 768px;
}

/* Base container */
.page-container {
  width: 100%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: var(--container-padding);
}

/* Grid layout */
.page-layout {
  display: grid;
  grid-template-columns: 
    var(--sidebar-width) 
    minmax(var(--content-min-width), var(--content-max-width)) 
    var(--right-sidebar-width);
  gap: var(--grid-gap);
  width: 100%;
  position: relative;
}

/* Main content column */
.main-content {
  grid-column: 2;
  position: relative;
  width: 100%;
  max-width: var(--content-max-width);
}

/* Content wrapper - for consistent padding and positioning */
.content-wrapper {
  position: relative;
  padding: var(--container-padding);
  background: var(--background-primary, #fff);
  border-radius: 8px;
}

/* Left sidebar */
.left-sidebar {
  grid-column: 1;
  position: sticky;
  top: var(--container-padding);
  height: calc(100vh - (2 * var(--container-padding)));
  padding: 1rem;
}

/* Right sidebar */
.right-sidebar {
  grid-column: 3;
  position: sticky;
  top: var(--container-padding);
  height: calc(100vh - (2 * var(--container-padding)));
  padding: 1rem;
}

/* Responsive adjustments */
@media (max-width: var(--breakpoint-lg)) {
  .page-layout {
    grid-template-columns: 
      calc(var(--sidebar-width) * 0.8)
      minmax(var(--content-min-width), var(--content-max-width))
      calc(var(--right-sidebar-width) * 0.8);
    gap: calc(var(--grid-gap) * 0.75);
  }
}

@media (max-width: var(--breakpoint-md)) {
  .page-layout {
    grid-template-columns: 1fr;
  }

  .left-sidebar,
  .right-sidebar {
    display: none;
  }

  .main-content {
    max-width: 100%;
  }
}

@media (max-width: var(--breakpoint-sm)) {
  :root {
    --container-padding: 1rem;
    --grid-gap: 1rem;
  }
}
