/* CSS Custom Properties */
:root {
  /* Colors */
  --primary-color: #3498db;
  --primary-color-light: #5dade2;
  --code-bg: #f6f8fa;
  
  /* Fonts */
  --font-mono: 'Fira Code', 'Consolas', monospace;
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1.25rem;
  --spacing-lg: 1.75rem;
  --spacing-xl: 2.5rem;
  
  /* Content Spacing */
  --content-padding-x: 3rem;
  --content-padding-y: 1.5rem;
  --content-max-width: 80ch;
  --section-spacing: 3rem;
  --sidenote-gap: 1rem;
  
  /* Typography */
  --font-size-sm: 0.875rem;
  --font-size-base: 1.125rem;
  --font-size-lg: 1.25rem;
  --line-height-base: 1.5;
  --line-height-relaxed: 1.75;
  --line-height-tight: 1.2;
  
  /* Layout */
  --max-width: 98%;
  --content-width: 75%;
  --left-column-width: 12%;
  --right-column-width: 13%;
  
  /* Effects */
  --border-radius: 8px;
  --transition-base: all 0.2s ease-in-out;
}
