@import './variables.css';
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');

/* Experimental Typography System */
.blog-post .content,
.content {
  --text-shadow-color: rgba(var(--primary-color-rgb), 0.1);
  font-family: 'Space Mono', monospace;
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.8;
  color: var(--text-color);
  
  /* Glitch Animation for Headings */
  @keyframes glitch {
    0% { transform: translate(0) }
    20% { transform: translate(-2px, 2px) }
    40% { transform: translate(-2px, -2px) }
    60% { transform: translate(2px, 2px) }
    80% { transform: translate(2px, -2px) }
    100% { transform: translate(0) }
  }
  
  /* Rotated Text Animation */
  @keyframes rotate {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  /* Rainbow Text Animation */
  @keyframes rainbow {
    0% { color: #ff0000 }
    20% { color: #ff8800 }
    40% { color: #ffff00 }
    60% { color: #00ff00 }
    80% { color: #0000ff }
    100% { color: #ff0000 }
  }
  
  /* Base Spacing with Random Variation */
  > * { 
    margin-bottom: calc(var(--spacing-lg) + (Math.random() * 1rem));
    transition: all 0.3s ease;
  }
  
  /* Headings */
  h1, h2, h3, h4 {
    font-family: 'Playfair Display', serif;
    color: var(--text-color);
    font-weight: 700;
    transform-origin: left center;
    position: relative;
    
    &:hover {
      animation: glitch 0.2s infinite;
      cursor: crosshair;
    }
  }
  
  h1 { 
    font-size: 4rem;
    line-height: 1;
    background: linear-gradient(45deg, var(--primary-color), var(--text-color));
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 4px 4px var(--text-shadow-color);
    
    &::before {
      content: "◕‿◕";
      position: absolute;
      left: -2rem;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    
    &:hover::before {
      opacity: 1;
    }
  }
  
  h2 { 
    font-size: 3rem;
    transform: skew(-5deg);
    padding-left: 2rem;
    border-left: 4px solid;
    border-image: linear-gradient(to bottom, var(--primary-color), transparent) 1;
  }
  
  h3 { 
    font-size: 2rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin: 2rem 0;
    padding-left: 1rem;
  }
  
  /* First Paragraph with Chaotic Letters */
  p:first-of-type {
    font-size: 1.2rem;
    
    &::first-letter {
      font-size: 4em;
      float: left;
      line-height: 1;
      margin: 0.1em 0.1em 0.2em 0;
      animation: rotate 20s linear infinite;
      transform-origin: 50% 50%;
    }
    
    /* Random letter animations */
    span:nth-child(3n) { animation: rainbow 4s infinite; }
    span:nth-child(3n+1) { transform: translateY(-2px); }
    span:nth-child(3n+2) { transform: rotate(5deg); }
  }
  
  /* Links with Experimental Hover */
  a {
    color: var(--primary-color);
    text-decoration: none;
    position: relative;
    padding: 0 0.2em;
    
    &::before {
      content: "→";
      position: absolute;
      left: -1.2em;
      opacity: 0;
      transition: all 0.3s ease;
    }
    
    &:hover {
      background: var(--primary-color);
      color: var(--bg-color);
      transform: skew(-10deg);
      
      &::before {
        opacity: 1;
        left: -0.8em;
      }
    }
  }
  
  /* Blockquotes as Circuit Boards */
  blockquote {
    margin: 2rem 0;
    padding: 1rem;
    background: 
      linear-gradient(90deg, var(--bg-color) 21px, transparent 1%) center,
      linear-gradient(var(--bg-color) 21px, transparent 1%) center,
      var(--primary-color);
    background-size: 22px 22px;
    border: none;
    color: var(--bg-color);
    font-family: 'Space Mono', monospace;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    
    &::before {
      content: "< />";
      position: absolute;
      top: -1rem;
      left: 1rem;
      background: var(--bg-color);
      color: var(--primary-color);
      padding: 0.5rem 1rem;
      transform: rotate(-5deg);
    }
  }
  
  /* Lists as Retro Computer Interface */
  ul, ol {
    list-style: none;
    padding: 1rem;
    background: var(--bg-hover);
    border: 2px solid var(--text-color);
    box-shadow: 4px 4px 0 var(--text-color);
  }
  
  li {
    margin: 0.5rem 0;
    padding-left: 2rem;
    position: relative;
    
    &::before {
      content: ">";
      position: absolute;
      left: 0;
      color: var(--primary-color);
      animation: blink 1s step-end infinite;
    }
  }
  
  /* Remove the old code block styling since we now have proper syntax highlighting */
  pre {
    margin: 1rem 0;
    overflow-x: auto;
  }
  
  code {
    font-family: 'Space Mono', monospace;
    max-width: 100%;
    white-space: pre-wrap;
  }

  /* Images with Glitch Effect */
  img {
    filter: grayscale(0.5) contrast(1.2);
    transition: all 0.3s ease;
    
    &:hover {
      filter: hue-rotate(90deg) contrast(1.5);
      transform: scale(1.02) rotate(1deg);
    }
  }
}

/* Blog Title */
.blog-title {
  font-size: clamp(3rem, 8vw, 6rem);
  font-family: 'Playfair Display', serif;
  background: linear-gradient(135deg, var(--primary-color), var(--text-color));
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 4px 4px var(--text-shadow-color);
  margin-bottom: 2rem;
  line-height: 1.2;
  text-align: left;
  transform: perspective(500px) rotateX(5deg);
  transition: transform 0.3s ease;
}

.blog-title:hover {
  transform: perspective(500px) rotateX(0deg);
}

/* Author Name */
.blog-author {
  font-family: 'Space Mono', monospace;
  font-size: 1.2rem;
  color: var(--primary-color);
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  background: linear-gradient(var(--bg-color), var(--bg-color)) padding-box,
              linear-gradient(45deg, var(--primary-color), transparent) border-box;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/* Date Styling */
.blog-date {
  font-family: 'Space Mono', monospace;
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.8;
  margin-left: 1rem;
  position: relative;
  padding-left: 1rem;
}

.blog-date::before {
  content: '•';
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

/* Tag Container */
.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
  text-align: left;
}

/* Tag Styling */
.tag {
  padding: 0.3rem 0.8rem;
  border-radius: 2rem;
  background: var(--bg-color);
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  font-size: 0.9rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tag:hover {
  background: var(--primary-color);
  color: var(--bg-color);
  transform: translateY(-2px);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blog-post .content,
  .content {
    transform: none;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    
    h1 { 
      font-size: clamp(2rem, 8vw, 3rem);
      text-shadow: 2px 2px var(--text-shadow-color);
      &::before {
        left: -1rem;
      }
    }
    
    h2 { 
      font-size: clamp(1.5rem, 6vw, 2.5rem);
      transform: none;
      padding-left: 1rem;
    }
    
    h3 { 
      font-size: clamp(1.2rem, 5vw, 2rem);
      writing-mode: horizontal-tb;
      margin: 1.5rem 0;
      padding-left: 0;
    }

    pre, code {
      max-width: 100%;
      overflow-x: auto;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin: 1.5rem 0;
      transform: none;
    }

    a::before {
      left: -1rem;
    }
  }
}