/* Shared Theme Variables */
:root {
  /* Colors */
  --text-primary: #333;
  --text-secondary: #666;
  --background-primary: #fff;
  --background-secondary: #f8f8f8;
  --background-accent: #f0f7ff;
  --background-accent-strong: #e6f3ff;
  --border-color: #ddd;
  --accent-color: #0066cc;
  --link-color: #0066cc;
  --link-hover-color: #004499;
  
  /* Typography */
  --font-size-base: 16px;
  --font-size-small: 0.9rem;
  --font-size-h1: 2.5rem;
  --font-size-h2: 2rem;
  --line-height-base: 1.6;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;
  
  /* Effects */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 4px 12px rgba(0, 102, 204, 0.15);
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  
  /* Transitions */
  --transition-fast: 0.2s;
  --transition-base: 0.3s;
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
}
