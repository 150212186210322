.thank-you-message {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  width: 280px;
  background: linear-gradient(135deg, var(--bg-color) 0%, rgba(255, 255, 255, 0.95) 100%);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1000;
  animation: slideUp 0.5s ease-out forwards;
  transform-origin: bottom left;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.thank-you-message p {
  margin: 0;
  line-height: 1.6;
  color: var(--text-color);
}

.thank-you-message p:first-child {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.thank-you-message a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.thank-you-message a:hover {
  color: var(--primary-color-light);
  text-decoration: underline;
}

@media (max-width: 600px) {
  .thank-you-message {
    width: calc(100% - 2rem);
    padding: 1rem;
    font-size: 0.9rem;
    left: 1rem;
    bottom: 1rem;
  }
}
