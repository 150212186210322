/* Contact.css */

.contact {
  display: flex;
  justify-content: center;
  
  margin-bottom: 1.5rem;
  flex-wrap: wrap; /* Allow contact items to wrap */
}

.contact a {
  font-size: var(--small-font-size);
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.contact a:hover {
  color: var(--link-hover-color);
  background-color: rgba(255, 255, 255, 0.2);
}
