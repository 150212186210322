.timeline-container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #1a1a2e, #16213e);
    overflow: hidden;
  }
  
  /* Add some basic styling for better visibility on the page */
  body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .timeline-container {
      height: 80vh;
    }
  }
  
  /* Optional: Add a loading indicator */
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5rem;
  }