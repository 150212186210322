/* bio.css, for a touch of the future */

.bio-container {
  max-width: 800px; /* Provide ample reading space while staying responsive */
  margin: 40px auto; /* Vertically spaced and centered */
  padding: 40px; /* Increased padding for a more spacious feel */
  border-radius: 20px; /* Larger curve radius for a softer look */
  background-color: #ebebeb; /* Light gray background */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); /* Subtler shadow for a lighter feel */
  font-family: 'Roboto Mono', monospace; /* Monospace font for a technical feel in headings */
}

.intro {
  font-size: 1.25rem; /* Increase font size for a more prominent intro */
  margin-bottom: 20px;
  line-height: 1.5; /* Adjust line height for better readability on larger font */
  color: #333; /* Dark gray for good contrast */
}

.expertise,
.projects { /* Include projects section in styling */
  margin-bottom: 40px; /* Increased spacing for a more luxurious look */
}

h2 {
  color: #333; /* Dark gray heading color for contrast */
  margin-bottom: 20px; /* Increased spacing for a more luxurious look */
  font-family: 'Roboto Mono', monospace; /* Monospace font for a technical feel in headings */
  font-size: 1.5rem; /* Slightly larger heading size for a balanced look */
  text-transform: uppercase; /* Convert headings to uppercase for emphasis */
  letter-spacing: 1px; /* Add slight letter spacing for improved readability */
}

.expertise h3,
.projects h3 {
  color: #007bff; /* Blue heading color for a touch of vibrancy */
  margin-bottom: 15px; /* Minor spacing adjustment */
  font-family: 'Roboto Mono', monospace; /* Monospace font for a technical feel in headings */
  font-size: 1.1rem; /* Slightly smaller heading size for a balanced look */
  text-transform: uppercase; /* Convert headings to uppercase for emphasis */
  letter-spacing: 1px; /* Add slight letter spacing for improved readability */
}

.expertise p,
.projects ul {
  color: #666; /* Lighter gray for body text */
  line-height: 1.6; /* Consistent line height */
}

.projects ul {
  list-style: none; /* Remove default disc */
  padding-left: 0; /* Reset padding for proper indentation */
}

.expertise li, /* Add project list items for styling consistency */
.projects li {
  margin-bottom: 10px; /* Add spacing between list items */
  padding-left: 20px; /* Indent list items with a space before the bullet */
}

.features li:before,
.stack li:before,
.expertise li:before,
.projects li:before {
  content: '>'; /* Add a tech-inspired bullet point */
  color: #007bff; /* Match bullet point color to headings */
  font-weight: bold; /* Make bullet point bolder */
  margin-left: -20px; /* Adjust bullet point position */
}

a { /* Style all links */
  color: #007bff; /* Match link color to headings */
  text-decoration: none; /* Remove underline */
}

a:hover {
  text-decoration: underline; /* Underline on hover for emphasis */
  color: #333; /* Change link hover color to dark gray */
}

