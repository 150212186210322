/* Footnote.css */
.footnote-wrapper {
  position: relative;
  display: inline;
}

.footnote {
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.footnote:hover {
  text-decoration: underline;
}

.footnote-number {
  color: #0066cc;
  font-size: 0.8em;
  border-bottom: 1px dotted #0066cc;
  transition: all 0.3s ease;
  padding: 0 2px;
  cursor: pointer;
  font-weight: bold;
  vertical-align: super;
}

.footnote.highlight .footnote-number {
  background-color: rgba(255, 255, 0, 0.3);
  border-radius: 3px;
}

.footnote-sidebar {
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 1rem;
  font-size: 0.9em;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.footnote-sidebar.highlight-border {
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.footnote-entry {
  position: absolute;
  left: 0;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  background: #fff;
  border-left: 2px solid #0066cc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2rem);
  font-size: 0.9rem;
  color: #666;
  pointer-events: none;
}

.footnote-entry.visible {
  opacity: 1;
  transform: translateX(0);
}

.footnote sup {
  color: #0066cc;
  font-weight: 500;
  transition: color 0.2s ease;
}

.footnote.visible sup {
  color: #004d99;
}

.footnotes-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100vh;
  padding: 20px;
  pointer-events: none;
  z-index: 1000;
  overflow: visible;
}

.sidenote {
  position: absolute;
  right: 20px;
  width: 250px;
  font-size: 0.9em;
  line-height: 1.4;
  color: #666;
  padding: 8px 12px;
  border-left: 2px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.85;
  transition: opacity 0.2s ease-out, border-left-color 0.2s ease-out;
  pointer-events: auto;
  z-index: 1;
  transform: translateY(-50%);
  margin-bottom: 10px;
}

.sidenote.active {
  border-left-color: #0066cc;
  background-color: #f0f7ff;
  opacity: 1;
  z-index: 2;
}

/* Ensure main content has proper positioning context */
.main-content {
  position: relative;
  margin-right: 300px;
  z-index: 1;
}

/* Mobile styles */
@media (max-width: 1200px) {
  .footnotes-container {
    width: 240px;
  }
  
  .main-content {
    margin-right: 260px;
  }
}

@media (max-width: 768px) {
  .footnotes-container {
    display: none;
  }
  
  .main-content {
    margin-right: 0;
  }
  
  .footnote {
    border-bottom: 1px dotted #0066cc;
  }
}

/* Hover interactions */
.footnote:hover .footnote-number {
  background-color: rgba(0, 102, 204, 0.1);
}

.footnote-sidebar:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Tooltip text visibility controlled */
.tooltip-text {
  visibility: hidden; /* Hidden by default */
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
}

.footnote:hover .tooltip-text {
  visibility: visible; /* Visible on hover */
}

/* Highlight effects for footnote numbers */
.footnote-number:hover, .footnote.highlight .footnote-number, .footnote-sidebar.highlight .footnote-number {
  background-color: #415a77; /* Dark slate blue for visibility */
}

/* Sidenote and Footnote mutual highlighting */
.footnote.highlight, .footnote-sidebar.highlight {
  background-color: #415a77;
  transform: scale(1.02);
  transition: background-color 0.5s ease, transform 0.3s ease;

}

/* Styling for sidenotes */
.footnote-sidebar {
  background-color: #333; /* Dark background for contrast */
  color: #fff; /* White text color for readability */
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9em; /* Moderate font size */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  margin-bottom: 10px; /* Space between sidenotes */
  width: 100%; /* Full width within the column */
}

.footnote-number-sidebar {
  background-color: #222; /* Slightly darker than sidebar for differentiation */
  color: white;
  padding: 5px;
  border-radius: 3px;
  display: block; /* Makes the number standout */
}

/* Sidebar styles for mobile */
@media (max-width: 900px) {
  .sidenote {
    display: block;
  }
  
  .footnote-sidebar {
    display: block;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f8f9fa;
  }
}

/* Ensure the sidebar stays fixed while scrolling */
.right-sidebar {
  position: sticky;
  top: 2rem;
  height: calc(100vh - 4rem);
  overflow: hidden;
  padding: 0;
  background: transparent;
}

/* Hide scrollbar but keep functionality */
.right-sidebar::-webkit-scrollbar {
  width: 0px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .right-sidebar {
    display: none;
  }
  
  .footnote-entry {
    position: static;
    opacity: 1;
    transform: none;
    margin-bottom: 1rem;
  }
}

/* Mobile styles */
@media (max-width: 1200px) {
  .sidenotes-wrapper {
    right: 1rem;
  }
  
  .sidenote {
    width: 230px;
  }
}

@media (max-width: 768px) {
  .footnotes-container,
  .sidenotes-wrapper {
    display: none;
  }
  
  .footnote {
    border-bottom: 1px dotted #0066cc;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .right-sidebar,
  .footnotes-container {
    display: none;
  }
  
  /* Show footnotes at the bottom on mobile */
  .footnotes-mobile {
    display: block;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
  }
  
  .footnotes-mobile .sidenote {
    position: static;
    margin-bottom: 1rem;
    opacity: 1;
    transform: none;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .sidenote {
    display: block;
  }
  
  .footnote {
    border-bottom: 1px dotted #0066cc;
  }
}
