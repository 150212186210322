/* coin.css */

/* Container for the coins */
.coin-container {
    display: flex;
    justify-content: space-around;
    gap: 0.5rem; /* Add gap between the coins */
    width: 100%;
    max-width: 300px; /* Limit the width for better centering */
    margin: 0 auto; /* Center the container */
  }
  
  /* Each coin element */
  .coin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    height: 150px; /* Ensure enough height for the canvas */
  }
  
  /* Ensure each canvas takes up the appropriate space */
  canvas {
    width: 80px;
    height: 80px;
    display: block;
  }
  
  /* Coin label */
  .coin-label {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    text-decoration: none;
    user-select: none;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .coin-container {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .coin {
      margin-bottom: 0.5rem;
    }
  
    canvas {
      width: 60px;
      height: 60px;
    }
  }
  