.terascale-research {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 95%;
    margin: auto;
  }

  /* Brain Styling Details */
.model-container-tars {
  width: 25vw;
  /* width: var(--model-container-width); */
  height: 25vw;
  /* height: var(--model-container-height); */
  border-radius: var(--model-container-border-radius);
  margin-bottom: 2rem;
  padding: 0.1rem; /* Adjust this value to increase/decrease spacing */
  overflow: hidden; /* Ensures the content does not spill over */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the content inside */
  margin: 0 auto; /* Centering in the available space */
}

.react-canvas-tars {
  width: 100%;
  height: 150px; /* Adjust height as needed */
}

  
  .terascale-research .summary h2 {
    color: #333;
  }
  
  .terascale-research .summary p {
    font-size: 16px;
    color: #666;
  }
  
  .details-button {
    width: 30%;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    background-color: #FF56b3;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0.3, 0.9);
  }
  
  .details-button:hover {
    background-color: #004494;
    box-shadow: none;
  }
  
  .details {
    padding-top: 20px;
    animation: slideIn 0.5s ease-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
