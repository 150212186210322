/* HomePage.css */

button:hover {
    background-color: var(--button-hover-bg-color);
}

/* Brain Styling Details */
.model-container {
    width: 50vw;
    /* width: var(--model-container-width); */
    height: 20rem;
    /* height: var(--model-container-height); */
    border-radius: var(--model-container-border-radius);
    margin-bottom: 2rem;
    padding: 0.1rem; /* Adjust this value to increase/decrease spacing */
    overflow: hidden; /* Ensures the content does not spill over */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the content inside */
    margin: 0 auto; /* Centering in the available space */
    
}

.react-canvas {
    width: 100%;
    height: 100px; /* Adjust height as needed */
}

/* Blog Post Styles */
.blog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: var(--default-padding);
}

.blog-post {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500vw; /* Set a maximum width for each tile */
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for a shadow effect */
    background-color: #fff; /* Optional: for a white background */
}

.blog-post h3 {
    font-size: var(--h2-font-size);
    margin-bottom: 1rem;
    text-align: center;
    font-family: 'Playfair Display', serif; /* More dramatic headline font */
}

.blog-post p {
    font-size: var(--paragraph-font-size);
    text-align: center;
    margin-bottom: 1rem;
}

/* Blog Section Styles */
.blog-section {
  padding: 4rem 0;
  background: #f8f9fa;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.blog-tiles-container {
  height: 400px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .model-container {
        width: 70vw;
        height: 40vw;
    }

    .blog-post {
        max-width: 90vw;
    }

    .blog-section {
      padding: 2rem 0;
    }

    .section-title {
      font-size: 2rem;
    }

    .blog-tiles-container {
      height: 300px;
    }
}