@import './variables.css';

/* Left Sidebar / Table of Contents */
.left-sidebar {
  grid-column: 1;
  position: sticky;
  top: var(--spacing-md);
}

.table-of-contents-nav {
  width: 100%;
  font-size: 0.9em;
}

/* Footnotes Container */
.footnotes-container {
  grid-column: 3;
  position: relative;
  width: 100%;
  padding-right: var(--spacing-md);
  margin-left: var(--spacing-xs);
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .left-sidebar {
    position: static;
    margin-bottom: var(--spacing-md);
    padding: 0;
    width: 100%;
  }
}

/* Webkit scrollbar styles */
.left-sidebar::-webkit-scrollbar {
  width: 6px;
}

.left-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.left-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.left-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.left-sidebar nav {
  padding: var(--spacing-sm);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .left-sidebar {
    display: none;
  }
}
