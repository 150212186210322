.table-of-contents {
  position: sticky;
  top: calc(var(--header-height) + 2rem);
  max-height: calc(100vh - var(--header-height) - 4rem);
  overflow-y: auto;
  padding: 1.5rem;
  font-size: 0.9rem;
  background: var(--bg-color);
  border-radius: 12px;
  width: 100%;
  max-width: 250px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.table-of-contents-inner {
  position: relative;
  height: 100%;
}

/* Thank you message */
.thank-you-message {
  position: fixed;
  top: auto;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
  background: linear-gradient(135deg, var(--bg-color) 0%, rgba(255, 255, 255, 0.95) 100%);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1000;
  animation: slideUp 0.5s ease-out forwards;
  transform-origin: bottom center;
  margin: 0;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media (min-width: 1600px) {
  .thank-you-message {
    bottom: 2rem;
    left: calc((100vw - 1910px) / 2 + 2rem);
    transform: none;
  }
}

@media (max-width: 1200px) {
  .thank-you-message {
    bottom: 2rem;
    right: 2rem;
    width: 250px;
  }
}

@media (max-width: 900px) {
  .thank-you-message {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 4rem);
    max-width: 500px;
  }
}

@media (max-width: 600px) {
  .thank-you-message {
    width: calc(100% - 2rem);
    padding: 1rem;
    font-size: 0.9rem;
    bottom: 1rem;
  }
}

.thank-you-message p {
  margin: 0;
  line-height: 1.6;
  color: var(--text-color);
}

.thank-you-message p:first-child {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.thank-you-message a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.thank-you-message a:hover {
  color: var(--primary-color-light);
  text-decoration: underline;
}

/* Progress bar container */
.reading-progress-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

/* Progress bar fill */
.reading-progress-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--primary-color) 0%,
    var(--primary-color-light) 100%
  );
  border-radius: 3px;
  transition: height 0.3s ease;
  box-shadow: 0 0 10px rgba(var(--primary-color-rgb), 0.2);
}

/* Glowing effect */
.reading-progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 3px;
}

.toc-content {
  flex: 1;
  padding-left: 1.5rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--text-color-light) transparent;
}

.toc-content::-webkit-scrollbar {
  width: 4px;
}

.toc-content::-webkit-scrollbar-track {
  background: transparent;
}

.toc-content::-webkit-scrollbar-thumb {
  background-color: var(--text-color-light);
  border-radius: 2px;
}

.table-of-contents nav > ul {
  margin: 0;
  padding: 0;
}

.table-of-contents ul {
  list-style: none;
  padding-left: 1rem;
}

.table-of-contents li {
  margin: 0.5rem 0;
  transition: all 0.2s ease;
}

.table-of-contents a {
  color: var(--text-color);
  text-decoration: none;
  transition: all 0.2s ease;
  display: block;
  padding: 0.25rem 0;
}

.table-of-contents a:hover {
  color: var(--primary-color);
}

.table-of-contents h2 {
  margin: 0 0 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
}

.table-of-contents a.active {
  color: var(--primary-color);
  border-left-color: var(--primary-color);
  background: var(--bg-hover);
  font-weight: 500;
  opacity: 1;
}

/* Heading level indentation */
.table-of-contents .heading-1 { margin-left: 0; }
.table-of-contents .heading-2 { margin-left: 0.5rem; }
.table-of-contents .heading-3 { margin-left: 1rem; }
.table-of-contents .heading-4 { margin-left: 1.5rem; }
.table-of-contents .heading-5 { margin-left: 2rem; }
.table-of-contents .heading-6 { margin-left: 2.5rem; }

/* Heading level font sizes */
.table-of-contents .heading-1 a { font-size: 1rem; }
.table-of-contents .heading-2 a { font-size: 0.95rem; }
.table-of-contents .heading-3 a { font-size: 0.9rem; }
.table-of-contents .heading-4 a { font-size: 0.85rem; }
.table-of-contents .heading-5 a { font-size: 0.8rem; }
.table-of-contents .heading-6 a { font-size: 0.75rem; }

@media (min-width: 1401px) {
  .table-of-contents {
    max-width: 250px;
    padding: 1.5rem;
  }
}

@media (max-width: 1400px) {
  .table-of-contents {
    max-width: 250px;
    padding: 1.25rem;
  }
}

@media (max-width: 1200px) {
  .table-of-contents {
    max-width: 220px;
    padding: 1rem;
    font-size: 0.85rem;
  }
}

@media (max-width: 1000px) {
  .table-of-contents {
    max-width: 200px;
    padding: 0.875rem;
  }
}

@media (max-width: 900px) {
  .table-of-contents {
    display: none;
  }
}
