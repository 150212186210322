/* Footer.css */

.footer {
    background: var(--header-footer-bg);
    color: var(--link-color);
    text-align: center;
    padding: var(--default-padding);
  }
  
  .footer p {
    margin: 0;
  }
  